<template>
  <div :style="{display:(block?'block':'inline-block')}">

    <el-button icon="el-icon-upload2"
               v-if="buttonType === 'icon'"
               @click="fileUpload">
      <slot></slot>
    </el-button>
    <el-button type="text"
               v-else
               @click="fileUpload">
      <slot></slot>
    </el-button>
    <el-dialog title="导入文件"
               v-loading="loading"
               modal
               :close-on-click-modal='false'
               @close="clearTimer"
               :visible.sync="dialogVisible"
               width="50%">
      <el-upload class="upload-demo"
                 action="/api/mall/admin/import/submit"
                 :data="{import_type:params.import_type,carry_data:JSON.stringify(params.carry_data)}"
                 v-if="resData && (resData.status === 'FAIL'|| resData.status === 'NORMAL' || resData.status ==='') "
                 :on-error="onError"
                 :before-upload="beforeupload"
                 :on-success="onSuccess"
                 :show-file-list="false">
        <el-button type="text"
                   icon="el-icon-upload2">选择文件</el-button>
      </el-upload>
      <div v-if="resData">
        <template v-if="resData && resData.status === 'SUBMITED'">
          <div>正在提交请求，请稍等</div>
        </template>
        <template v-else-if="resData.status === 'PENDING'">
          <el-row>正在导入，请勿关闭</el-row>
          <el-progress :text-inside="true"
                       class="mt-15"
                       :stroke-width="24"
                       :percentage="percent"
                       status="success"></el-progress>
        </template>
        <template v-else-if="resData.status === 'NORMAL'">
          <el-row>
            总共{{resData.total}}条数据，成功导入{{resData.success_num}}条，失败{{resData.fail_num}}条
          </el-row>
          <el-row v-if="resData.fail_data.length>0">
            <el-button type="text"
                       @click="dowloadFail">下载失败原因</el-button>
          </el-row>
        </template>

        <template v-else-if="resData.status === 'FAIL'">{{ errMsg }}</template>
      </div>

      <span slot="footer"
            class="dialog-footer">
        <el-button @click="clearTimer">取 消</el-button>
        <el-button type="primary"
                   @click="clearTimer">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import Papa from 'papaparse'
import { getUploadStatus } from '@/api'
export default {
  props: {
    params: Object,
    block: Boolean,
    buttonType: String
  },
  data () {
    return {
      dialogVisible: false,
      timer: null, // 计时器
      resData: {
        status: ''
      },
      loading: false,
      formdata: null,
      itemList: [
        {
          失败原因: '红木'
        },
        {
          失败原因: '红木'
        }
      ]
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
    percent () {
      if (this.resData) {
        if (parseInt(this.resData.run_total) > 0) {
          return parseInt(((parseInt(this.resData.run_total) / parseInt(this.resData.total)) * 100).toFixed(0))
        } else {
          return 0
        }
      } else {
        return 0
      }
    }
  },
  methods: {
    dowloadFail () {
      const parseJson = []
      this.resData.fail_data.map((item) => {
        parseJson.push({
          失败原因: item
        })
      })
      var csv = Papa.unparse(parseJson)
      // 定义文件内容，类型必须为Blob 否则createObjectURL会报错
      const content = new Blob([csv])
      // 生成url对象
      const urlObject = window.URL || window.webkitURL || window
      const url = urlObject.createObjectURL(content)
      // 生成<a></a>DOM元素
      const el = document.createElement('a')
      // 链接赋值
      el.href = url
      el.download = '失败原因.cvs'
      // 必须点击否则不会下载
      el.click()
      // 移除链接释放资源
      urlObject.revokeObjectURL(url)
    },
    beforeupload (e) {
      this.loading = true
    },
    onError (e) {
      this.loading = false
    },
    onSuccess (e) {
      this.loading = false
      if (e.meta.code === 0) {
        this.getUploadStatus()
      } else {
        this.$message.error(e.meta.msg)
      }
    },
    clearTimer () {
      this.resData = {
        status: ''
      }
      clearTimeout(this.timer)
      this.dialogVisible = false
    },
    async fileUpload () {
      // this.formData = JSON.stringify(this.params)
      this.dialogVisible = true
      this.getUploadStatus()
      // this.fileUpload()
    },
    async getUploadStatus () {
      // import_type:'card_keys_data',carry_data:{card_id:scope.row.id}
      const res = await getUploadStatus(this.params)
      if (res.meta.code === 0) {
        this.resData = res.data.importData
        if (res.data.importData.status === 'NORMAL' || res.data.importData.status === 'FAIL') {
          clearTimeout(this.timer)
        } else {
          this.timer = setTimeout(() => {
            this.getUploadStatus()
          }, 1000)
        }
      } else if (res.meta.code === 4004) {
        this.resData.status = ''
      } else {
        this.$message.error(res.meta.msg)
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
